import { useState } from "react";
import styles from "./TRD.module.scss";
import axios from "axios";

function TRD() {
  const [results, setResults] = useState({
    dsResult: [],
    summaryResult: [],
    bblResult: [],
  });
  const [inputs, setInputs] = useState({
    urlInput: "",
    summaryInput: "",
    bblInput: "",
  });

  async function handleURLInput(e) {
    e.preventDefault();
    const { data } = await axios.post("http://127.0.0.1:8000/utils/read", {
      urls: inputs.urlInput.split("\n"),
    });
    setResults({ ...results, dsResult: data.result });
  }

  async function fetchSite(e) {
    const { data } = await axios.get(
      "http://127.0.0.1:8000/utils/" + e.target.name,
    );
    setResults({ ...results, dsResult: data.result });
  }

  async function writeArticle(e) {
    e.preventDefault();
    const { data } = await axios.post(
      "http://127.0.0.1:8000/utils/write-article",
      {
        summaries: inputs.summaryInput.split("\n"),
      },
    );
    setResults({ ...results, summaryResult: data.result });
  }

  async function getBBL(e) {
    e.preventDefault();
    const { data } = await axios.post("http://127.0.0.1:8000/utils/bbls", {
      addresses: inputs.bblInput.split("\n"),
    });
    setResults({ ...results, bblResult: data.result });
  }

  function handleInput(e) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  return (
    <div>
      <form
        className={styles.urlList}
        onSubmit={handleURLInput}
        onChange={handleInput}
      >
        <textarea
          value={inputs.urlInput}
          name="urlInput"
          id="URL-list"
        ></textarea>
        <button type="submit">ENTER</button>
      </form>
      <button name="com-ob" onClick={fetchSite}>
        COM OB
      </button>
      <button name="trd" onClick={fetchSite}>
        TRD
      </button>
      <button name="re-business" onClick={fetchSite}>
        RE BUSINESS
      </button>
      <button name="all" onClick={fetchSite}>
        ALL
      </button>
      <p>
        {results.dsResult.map((item) => (
          <>
            {item}
            <br />
          </>
        ))}
      </p>
      <form
        className={styles.summaryMaker}
        onChange={handleInput}
        onSubmit={writeArticle}
      >
        <textarea name="summaryInput" value={inputs.summaryInput}></textarea>
        <button type="submit">ENTER</button>
        <button>TOGGLE</button>
        <span>Office</span>
      </form>
      <p>
        {results.summaryResult.map((item) => (
          <>
            {item}
            <br />
            <br />
          </>
        ))}
      </p>
      <form
        className={styles.bblField}
        onChange={handleInput}
        onSubmit={getBBL}
      >
        <textarea name="bblInput" value={inputs.bblInput}></textarea>
        <button type="submit">ENTER (BBL)</button>
      </form>
      <p>
        {results.bblResult.map((item) => (
          <>
            {item}
            <br />
          </>
        ))}
      </p>
    </div>
  );
}

export default TRD;
