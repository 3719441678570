// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TechStack_techStack__29NGa {
  height: calc(75% - 2.5em);
}
.TechStack_techStack__29NGa h2 {
  text-align: center;
}
.TechStack_techStack__29NGa a {
  text-decoration: underline;
}
.TechStack_techStack__29NGa ul,
.TechStack_techStack__29NGa ol {
  margin-inline: auto;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
}
.TechStack_techStack__29NGa li {
  font-size: 22px;
  padding: 0.5em;
}
.TechStack_techStack__29NGa > div {
  display: flex;
}
.TechStack_techStack__29NGa > div > div {
  width: 50%;
}

@media (max-width: 900px) {
  .TechStack_techStack__29NGa {
    height: auto;
  }
  .TechStack_techStack__29NGa > div {
    flex-direction: column;
  }
  .TechStack_techStack__29NGa > div > div {
    margin-top: 1em;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/projectPage/TechStack.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,0BAAA;AAAJ;AAGE;;EAEE,mBAAA;EACA,gBAAA;EACA,uBAAA;EAAA,kBAAA;AADJ;AAIE;EACE,eAAA;EACA,cAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAKI;EACE,UAAA;AAHN;;AAQA;EACE;IACE,YAAA;EALF;EAOE;IACE,sBAAA;EALJ;EAOI;IACE,eAAA;IACA,WAAA;EALN;AACF","sourcesContent":[".techStack {\n  height: calc(75% - 2.5em);\n\n  & h2 {\n    text-align: center;\n  }\n\n  & a {\n    text-decoration: underline;\n  }\n\n  & ul,\n  ol {\n    margin-inline: auto;\n    text-align: left;\n    width: fit-content;\n  }\n\n  & li {\n    font-size: 22px;\n    padding: 0.5em;\n  }\n\n  & > div {\n    display: flex;\n\n    & > div {\n      width: 50%;\n    }\n  }\n}\n\n@media (max-width: 900px) {\n  .techStack {\n    height: auto;\n\n    & > div {\n      flex-direction: column;\n\n      & > div {\n        margin-top: 1em;\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"techStack": `TechStack_techStack__29NGa`
};
export default ___CSS_LOADER_EXPORT___;
