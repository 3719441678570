import { HomeFeed, IntroCard } from "../components/homepage";
import styles from "./FullPageStyle.module.scss";
import { homeSections } from "../content";
import { useState } from "react";

function Homepage() {
  const [focus, setFocus] = useState("");

  return (
    <div className={styles.siteBackground}>
      <div className={styles.homePage}>
        <IntroCard setFocus={setFocus} />
        <HomeFeed sections={homeSections} focus={focus} />
      </div>
    </div>
  );
}

export default Homepage;
