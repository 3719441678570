// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocNav_docNav__012gF {
  width: 10vw;
  overflow-y: scroll;
  margin: 1em;
  min-width: 150px;
}
.DocNav_docNav__012gF > h3,
.DocNav_docNav__012gF > h4 {
  cursor: pointer;
}
.DocNav_docNav__012gF > h3:hover,
.DocNav_docNav__012gF > h4:hover {
  text-decoration: underline;
}
.DocNav_docNav__012gF > h3 {
  margin-block: 1em;
}
.DocNav_docNav__012gF > h4 {
  margin-block: 0.5em;
  margin-left: 10px;
}

@media (max-width: 480px) {
  .DocNav_docNav__012gF {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/DocNav.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;;EAEE,eAAA;AACJ;AACI;;EACE,0BAAA;AAEN;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,iBAAA;AADJ;;AAKA;EACE;IACE,aAAA;EAFF;AACF","sourcesContent":[".docNav {\n  width: 10vw;\n  overflow-y: scroll;\n  margin: 1em;\n  min-width: 150px;\n\n  & > h3,\n  > h4 {\n    cursor: pointer;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n\n  & > h3 {\n    margin-block: 1em;\n  }\n\n  & > h4 {\n    margin-block: 0.5em;\n    margin-left: 10px;\n  }\n}\n\n@media (max-width: 480px) {\n  .docNav {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docNav": `DocNav_docNav__012gF`
};
export default ___CSS_LOADER_EXPORT___;
