// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeFeed_homeFeed__9oSiJ {
  overflow-y: scroll;
  padding: 3em;
}

@media (max-width: 480px) {
  .HomeFeed_homeFeed__9oSiJ {
    padding: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/HomeFeed.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,YAAA;EACF;AACF","sourcesContent":[".homeFeed {\n  overflow-y: scroll;\n  padding: 3em;\n}\n\n@media (max-width: 480px) {\n  .homeFeed {\n    padding: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeFeed": `HomeFeed_homeFeed__9oSiJ`
};
export default ___CSS_LOADER_EXPORT___;
