import styles from "./CrosswordExample.module.scss";
import Crossword, {
  examplePuzzleFull,
  examplePuzzleShort,
} from "ultimate-react-crossword";
import { CodeBlock } from "../utilities";

function onPuzzleCorrect(crosswordData) {
  alert("Congrats!");
  return crosswordData.map((row) =>
    row.map((square) => {
      if (square) {
        return { ...square, disabled: true };
      } else {
        return square;
      }
    }),
  );
}

function CrosswordExample() {
  return (
    <div className={"card " + styles.crosswordExample}>
      <Crossword
        data={examplePuzzleFull.grid}
        acrosses={examplePuzzleFull.acrosses}
        downs={examplePuzzleFull.downs}
        puzzleClassnames={styles.puzzle}
        crosswordClassnames={styles.crossword}
        clueMenuClassnames={styles.clueMenu}
        clueListClassnames={styles.crosswordClues}
        onPuzzleCorrect={onPuzzleCorrect}
      />
      <h2>The code that put this on your screen:</h2>
      <CodeBlock
        style="JS"
        lines={[
          'import Crossword, { examplePuzzleFull } from "ultimate-react-crossword";',
          "",
          "function CrosswordExample() {",
          "  return (",
          "    <div>",
          "      <Crossword",
          "        data={examplePuzzleFull.grid}",
          "        acrosses={examplePuzzleFull.acrosses}",
          "        downs={examplePuzzleFull.downs}",
          "      />",
          "    </div>",
          "  );",
          "}",
        ]}
      />
    </div>
  );
}

export default CrosswordExample;
