function DocListItem({ item }) {
  let ps = [];
  let ph = "";
  for (let i = 0; i < item.length; i++) {
    const letter = item[i];
    if (letter === "`") {
      ps.push(ph);
      ph = "";
    } else if (i === item.length - 1) {
      ph += letter;
      ps.push(ph);
    } else {
      ph += letter;
    }
  }

  return <li>{ps.map((p, i) => (i % 2 !== 0 ? <span>{p}</span> : p))}</li>;
}

export default DocListItem;
