import styles from "./TechStack.module.scss";
import { Link } from "react-router-dom";

function TechStack({ info }) {
  return (
    <div className={"card " + styles.techStack}>
      <h2>Tech Stack</h2>
      <div>
        <div>
          <h2>Front End</h2>
          <ul>
            {info.front.map((item) => (
              <li>
                {item.url ? <Link to={item.url}>{item.text}</Link> : item}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2>Back End</h2>
          <ul>
            {info.back.map((item) => (
              <li>
                {item.url ? (
                  <Link to={item.url} target="_blank">
                    {item.text}
                  </Link>
                ) : (
                  item
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TechStack;
