import styles from "./FlexSection.module.scss";
// import DocSection from "./DocSection";
import DocBlock from "./DocBlock";

function FlexSection({ direction, blocks, size, flex, justify, align }) {
  const styleObject = flex && size ? { flex: `0 0 ${size}` } : {};
  if (justify) {
    styleObject.justifyContent = justify;
  }
  if (align) {
    styleObject.alignContent = align;
  }
  return (
    <div
      className={
        styles.flex +
        " " +
        (direction === "h" ? styles.horizontalFlex : styles.verticalFlex)
      }
      style={styleObject}
    >
      {blocks.map((block) => (
        <DocBlock block={block} flex={true} />
      ))}
    </div>
  );
}

export default FlexSection;
