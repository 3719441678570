// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Documentation_docs__rNu4d {
  overflow-y: scroll;
  max-width: calc(90% - 2em);
}
.Documentation_docs__rNu4d > div {
  width: 75%;
  margin-inline: auto;
  margin-block: 2em;
}
.Documentation_docs__rNu4d p,
.Documentation_docs__rNu4d li {
  font-size: 20px;
}
.Documentation_docs__rNu4d ul,
.Documentation_docs__rNu4d ol {
  margin-inline: 2em;
}

@media (max-width: 480px) {
  .Documentation_docs__rNu4d {
    width: 100%;
    max-width: unset;
  }
  .Documentation_docs__rNu4d div {
    flex-direction: column !important;
    word-break: break-word;
  }
  .Documentation_docs__rNu4d div pre > div {
    flex-direction: row !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/Documentation.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,0BAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAEE;;EAEE,eAAA;AAAJ;AAGE;;EAEE,kBAAA;AADJ;;AAKA;EACE;IACE,WAAA;IACA,gBAAA;EAFF;EAGE;IACE,iCAAA;IACA,sBAAA;EADJ;EAGI;IACE,8BAAA;EADN;AACF","sourcesContent":[".docs {\n  overflow-y: scroll;\n  max-width: calc(90% - 2em);\n\n  & > div {\n    width: 75%;\n    margin-inline: auto;\n    margin-block: 2em;\n  }\n\n  & p,\n  li {\n    font-size: 20px;\n  }\n\n  & ul,\n  ol {\n    margin-inline: 2em;\n  }\n}\n\n@media (max-width: 480px) {\n  .docs {\n    width: 100%;\n    max-width: unset;\n    & div {\n      flex-direction: column !important;\n      word-break: break-word;\n\n      & pre > div {\n        flex-direction: row !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docs": `Documentation_docs__rNu4d`
};
export default ___CSS_LOADER_EXPORT___;
