import styles from "./FullPageStyle.module.scss";
import { useParams } from "react-router-dom";
import { CrosswordExample } from "../components/NPMpages";

function NPMExample() {
  const { packageName } = useParams();

  return (
    <div className={styles.siteBackground}>
      <div className={styles.npmExamplePage}>
        {packageName === "ultimate-react-crossword" ? (
          <CrosswordExample />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default NPMExample;
