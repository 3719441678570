import styles from "./IntroCard.module.scss";
import { Link } from "react-router-dom";

function NavLink({ setFocus, sectionId, text }) {
  return (
    <h3
      onClick={() => {
        setFocus(sectionId);
      }}
    >
      {text}
    </h3>
  );
}

function IntroCard({ setFocus }) {
  return (
    <aside className={`card ${styles.introCard}`}>
      <section className={styles.nameTag}>
        <img src="/headshot.png" />
        <h1>Lucas McGill</h1>
        <h2>Software Engineer</h2>
      </section>
      <section className={styles.siteLinks}>
        <NavLink sectionId="about" text="About Me" setFocus={setFocus} />
        <NavLink sectionId="projects" text="My Projects" setFocus={setFocus} />
        <NavLink sectionId="npm" text="My NPM Packages" setFocus={setFocus} />
        <NavLink sectionId="contact" text="Contact Me" setFocus={setFocus} />
      </section>
      <section className={styles.socialLinks}>
        <Link to="https://www.linkedin.com/in/lucas-mcgill/" target="_blank">
          <img src="/linkedin logo.png" />
        </Link>
        <Link to="https://github.com/LucasMc17" target="_blank">
          <img src="/github logo.png" />
        </Link>
      </section>
    </aside>
  );
}

export default IntroCard;
