// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes IntroCard_popin__PzOhO {
  0% {
    translate: -100px 0;
    opacity: 0;
  }
  100% {
    translate: 0 0;
    opacity: 1;
  }
}
.IntroCard_introCard__reEnR {
  animation: 0.5s ease-in-out IntroCard_popin__PzOhO;
  width: 25%;
  height: calc(100% - 4rem);
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1em;
}
.IntroCard_introCard__reEnR h2,
.IntroCard_introCard__reEnR h3 {
  text-align: center;
}

@media (max-width: 480px) {
  .IntroCard_introCard__reEnR {
    width: calc(100% - 4em);
    max-width: auto;
    min-width: auto;
    height: auto;
  }
  .IntroCard_siteLinks__FjYnZ {
    display: none;
  }
  .IntroCard_socialLinks__Vk95X {
    padding-top: 2em;
  }
}
.IntroCard_nameTag__H6mro {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.IntroCard_nameTag__H6mro > img {
  width: 80%;
}

.IntroCard_siteLinks__FjYnZ h3 {
  padding: 0.5em 0;
  transition-duration: 0.5s;
  cursor: pointer;
}
.IntroCard_siteLinks__FjYnZ h3:hover {
  color: gray;
}

.IntroCard_socialLinks__Vk95X {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.IntroCard_socialLinks__Vk95X img {
  object-fit: cover;
  width: 75px;
  height: 75px;
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/IntroCard.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAA;IACA,UAAA;EACF;EAEA;IACE,cAAA;IACA,UAAA;EAAF;AACF;AAGA;EACE,kDAAA;EACA,UAAA;EACA,yBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AADF;AAGE;;EAEE,kBAAA;AADJ;;AAKA;EACE;IACE,uBAAA;IACA,eAAA;IACA,eAAA;IACA,YAAA;EAFF;EAKA;IACE,aAAA;EAHF;EAMA;IACE,gBAAA;EAJF;AACF;AAOA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;AALF;AAOE;EACE,UAAA;AALJ;;AASA;EACE,gBAAA;EACA,yBAAA;EACA,eAAA;AANF;AAQE;EACE,WAAA;AANJ;;AAUA;EACE,aAAA;EACA,8BAAA;EACA,UAAA;AAPF;AASE;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AAPJ","sourcesContent":["@keyframes popin {\n  0% {\n    translate: -100px 0;\n    opacity: 0;\n  }\n\n  100% {\n    translate: 0 0;\n    opacity: 1;\n  }\n}\n\n.introCard {\n  animation: 0.5s ease-in-out popin;\n  width: 25%;\n  height: calc(100% - 4rem);\n  min-width: 300px;\n  max-width: 400px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  margin: 1em;\n\n  & h2,\n  h3 {\n    text-align: center;\n  }\n}\n\n@media (max-width: 480px) {\n  .introCard {\n    width: calc(100% - 4em);\n    max-width: auto;\n    min-width: auto;\n    height: auto;\n  }\n\n  .siteLinks {\n    display: none;\n  }\n\n  .socialLinks {\n    padding-top: 2em;\n  }\n}\n\n.nameTag {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1em;\n\n  & > img {\n    width: 80%;\n  }\n}\n\n.siteLinks h3 {\n  padding: 0.5em 0;\n  transition-duration: 0.5s;\n  cursor: pointer;\n\n  &:hover {\n    color: gray;\n  }\n}\n\n.socialLinks {\n  display: flex;\n  justify-content: space-between;\n  width: 90%;\n\n  & img {\n    object-fit: cover;\n    width: 75px;\n    height: 75px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introCard": `IntroCard_introCard__reEnR`,
	"popin": `IntroCard_popin__PzOhO`,
	"siteLinks": `IntroCard_siteLinks__FjYnZ`,
	"socialLinks": `IntroCard_socialLinks__Vk95X`,
	"nameTag": `IntroCard_nameTag__H6mro`
};
export default ___CSS_LOADER_EXPORT___;
