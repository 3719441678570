import styles from "./ProjectHero.module.scss";
import { Link } from "react-router-dom";

function ProjectHero({ info }) {
  return (
    <div className={styles.projectHero}>
      <Link className="card" to="/">
        <h4>Back to Home</h4>
      </Link>
      <img src={info.image} />
      <header className="card">
        <div>
          <h1>{info.name}</h1>
          <div>
            <h2>{info.role}</h2>
            <h2>{info.dates}</h2>
          </div>
        </div>
      </header>
    </div>
  );
}

export default ProjectHero;
