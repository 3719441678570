import styles from "./DocImage.module.scss";

function DocImage({ src, flex, size }) {
  const styleObject = flex && size ? { flex: `0 0 ${size}` } : {};
  return (
    <div className={styles.docImage} style={styleObject}>
      <img src={src} />
    </div>
  );
}

export default DocImage;
