import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Homepage, NPMExample, PackageDocPage, Project } from "./layouts";
import { TRD } from "./components/utilities";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/projects",
    element: <Homepage />,
  },
  {
    path: "/projects/:projectName",
    element: <Project />,
  },
  {
    path: "/packages",
    element: <Homepage />,
  },
  {
    path: "/packages/:packageName/example",
    element: <NPMExample />,
  },
  {
    path: "/packages/:packageName/docs",
    element: <PackageDocPage />,
  },
  {
    path: "/secret-trd-utils",
    element: <TRD />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
