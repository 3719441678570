// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocUnorderedList_docUnorderedList__xPzxC span {
  font-family: monospace;
  color: #ad5c5c;
  background-color: lightgray;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0 3px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/DocUnorderedList.module.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,cAAA;EACA,2BAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAAJ","sourcesContent":[".docUnorderedList {\n  & span {\n    font-family: monospace;\n    color: #ad5c5c;\n    background-color: lightgray;\n    border: 1px solid gray;\n    border-radius: 5px;\n    padding: 0 3px;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docUnorderedList": `DocUnorderedList_docUnorderedList__xPzxC`
};
export default ___CSS_LOADER_EXPORT___;
