// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocImage_docImage__Q6axk > img {
  width: calc(100% - 2em);
  margin: 1em;
  box-shadow: 0px 0px 20px lightgray;
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/DocImage.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,WAAA;EACA,kCAAA;AAAJ","sourcesContent":[".docImage {\n  & > img {\n    width: calc(100% - 2em);\n    margin: 1em;\n    box-shadow: 0px 0px 20px lightgray;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docImage": `DocImage_docImage__Q6axk`
};
export default ___CSS_LOADER_EXPORT___;
