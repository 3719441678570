const projects = [
  {
    name: "Billfold",
    slug: "billfold",
    image: "/BillFoldMockup.png",
    dates: "March, 2022 - Present",
    tagline: "The only budgeting app you'll ever need",
    role: "Sole Developer",
    shortDescription: [
      "Billfold is a comprehensive budgeting and expense tracking app. Setting up an account is easy: just enter your income, yearly expenses like taxes, monthly expenses like rent, then divide your leftover income into expense categories. Once you start logging purchases, Billfold will track your spending month over month, letting you know when you go over budget. Billfold's charts make it easy to see what you're spending too much on, and can easily visualize data from up to 18 months at a time!",
      "For a quick demo of a prepopulated account, log in as user 'cody', password '123'!",
    ],
    fullDescription: [
      "Billfold has come a long way! The project began its life when my wife asked me to help her create a budgeting spreadsheet. What Excel whizz could resist? Over the course of an afternoon, I whipped up a spreadsheet full of custom functions, calculating expenses and dividing daily purchases into categories to help the user keep track of their spending across the month.",
      "A year or so later, my coding bootcamp assigned me a hackathon project. I had to turn over some kind of functioning app in the space of a weekend, and I knew exactly what I wanted to build. 48 hours later, Billfold 1.0 was launched!",
      "I was very proud of what I had managed to put out in such a short timeframe, but I knew there was more functionality to be built. Billfold remains an active project of mine to this day, regularly receiving small tweaks and updates. It’s data visualizations have become particularly robust, able to spontaneously generate many useful graphs based on spending data, from a pie chart demonstrating money allocation across the year, to a bar chart showing how your spending compared to your budget each month, to a line chart tracking how on target you are to stay within your budget for the current month.",
      "After so many years of active development, Billfold has become a powerful budgeting tool, and I believe anyone who’s looking to get a better sense of their spending habits can benefit from it. After all, my wife still uses it to this day, and so do I!",
      "To see an account already populated with some data, log in with the username 'cody' and the password '123'",
    ],
    techStack: {
      front: ["JavaScript", "React", "Redux", "react-chart-js2"],
      back: ["Node.js", "Express", "Sequelize", "PostgreSQL"],
    },
    links: [
      {
        url: "https://billfold.pro",
        text: "Try me out!",
        newTab: true,
      },
      {
        url: "https://github.com/LucasMc17/Billfold",
        text: "See my code!",
        newTab: true,
      },
    ],
  },
  {
    name: "CrossWars",
    slug: "crosswars",
    image: "/CrossWarsMockup.png",
    dates: "March, 2022",
    tagline: "The crossword puzzle for those with a competitive streak",
    role: "Developer",
    shortDescription: [
      "CrossWars is a real-time, online multiplayer game where you can compete against up to three of your friends (or foes) to solve a crossword puzzle.",
      "Points are awarded per correct word completed, but stay sharp - each word you complete brings your opponents closer to completing the crossword too. With the timer counting down, who can secure the most points and take the crown of the ultimate cross warrior?",
    ],
    fullDescription: [
      "CrossWars was my keystone project for Fullstack Academy, working alongside Dipti Yadav, Omar Abbasi and Sam Dokus, three other amazing developers. As the culmination of our experience learning web development, it represents a metric ton of accrued knowledge and hard work from each of us, and it remains an achievement we are very proud of.",
      "We knew very early in the project that we wanted to make a real-time multiplayer game, but we were less sure exactly what form we wanted it to take. Being a long-time cruciverbalist (look it up) I suggested we make some kind of competitive crossword-solving game, and from there the ideas came pouring in from all sides. We quickly decided on the basic rules and before long we had our work cut out for us— from both a development and a game-design perspective.",
      "First the development side: This being a real time game, we knew we would have to get very familiar with websockets. This became the first area of focus for us: identifying what events should send updates to all players, and what information each event should contain. Secondly, we needed to build a functioning React crossword component, a daunting task considering we were on a deadline. We ultimately found a react crossword component created by Jared Reisinger (shout out Jared) and decided to make use of it, as it had robust event handling options. Considering the custom nature of our game, we knew this would be important. Lastly, we had to source a lot, and I mean a lot, of crossword data. Thankfully, we found a repository of NYT crosswords as JSON objects. From there it was just a matter of writing a script to convert them to a format that the component could accept.",
      "As for game design: We knew that the biggest challenge we faced was turning something as deliberate and slow-paced as a crossword puzzle into something frenetic and energetic. How do we light the fire of competition with a few crossed words? The answer turned out to be three-fold: First, we broke the puzzles out by difficulty, allowing newbies to cut their teeth on a Monday puzzle while veterans could blitz through Fridays and Saturdays. Second, we settled on a ten minute timer for all games. This ensured that no game dragged on longer than was fun for all parties. And finally, we came up with something pretty brilliant, if I do say so myself. We decided that any time a player solved a clue, it was revealed for all other players. While this may sound antithetical to a crossword puzzle, it turned out to be the linchpin of our game. Now, instead of four people racing separately to complete as much of a crossword puzzle as they could before the timer was up, it was four people feverishly solving one puzzle. Each correct answer revealed more of the board and gave competitors more to work with. This change added much needed tension and strapped a rocket to the pace of gameplay. Before long, we were finishing entire puzzles long before that ten minute timer had a chance to tick down. CrossWars was officially the multiplayer mad-dash we knew it could be. And crosswords were finally cool.",
      "CrossWars speaks for itself as a final project, and we couldn’t be prouder to have brought it into existence.",
    ],
    techStack: {
      front: [
        "JavaScript",
        "Socket.io",
        "React",
        "Redux",
        {
          text: "React Crossword",
          url: "https://github.com/JaredReisinger/react-crossword",
        },
        "anime.js",
      ],
      back: [
        "Node.js",
        "Express",
        "Sequelize",
        "PostgreSQL",
        {
          url: "https://github.com/doshea/nyt_crosswords",
          text: "nyt_crosswords JSON repo",
        },
      ],
    },
    links: [
      {
        url: "https://cross-wars.herokuapp.com/",
        text: "Try me out!",
        newTab: true,
      },
      {
        url: "https://github.com/Cross-Wars/Cross-Wars-v2",
        text: "See my code!",
        newTab: true,
      },
    ],
  },
  {
    name: "Pamela Nadell - Author Homepage",
    slug: "pamelanadellhomepage",
    image: "/PamWithSpac.png",
    dates: "July, 2022",
    tagline: "",
    role: "Sole Developer",
    shortDescription: [
      "I refreshed and redesigned the homepage of Pamela Nadell's official website. Pamela Nadell is an acclaimed author and Patrick Clendenen Chair in Women's and Gender History at American University.",
      "The site is hosted by WordPress, and was redesigned in July of 2022 to better highlight Pamela's most recent book, and give users a comprehensive overview of the site's content in one centralized location. This redesign was accomplished with direction from Pamela Nadell herself, and utilized a mixture of custom HTML/CSS, as well as WordPress' own site-building tools, to ensure ease of future editing.",
    ],
    fullDescription: [
      "In 2022, I was approached by Pamela Nadell, an acclaimed author and Patrick Clendenen Chair in Women's and Gender History at American University, to redesign her website. In particular, she was looking to give the site a sleeker feel, and to highlight her book America’s Jewish Women.",
      "The challenges in this redesign sprang mostly from the limitations of the WordPress CMS. Pamela Nadell had a very clear vision for the site, but the tools of the CMS did not always natively allow for some of the specific requests she had in mind. However the CMS itself was, understandably, non-negotiable, since it allowed her to easily add and edit content on the site without needing to rewrite any actual code. As a result, I had to get pretty clever to achieve the look we both ultimately wanted for the site. The hero banner at the top of the homepage, for example, took some careful CSS with plenty of breakpoints based on screen size to create. Thankfully, via a mix of clever usage of the WordPress site design tools and plenty of custom HTML and CSS, I was able to redesign the site to the client’s exact specifications, and the result still looks great today!",
    ],
    techStack: {
      front: ["HTML", "CSS"],
      back: ["WordPress CMS"],
    },
    links: [
      {
        url: "https://pamelanadell.com/",
        text: "Visit the site!",
        newTab: true,
      },
    ],
  },
  {
    name: "PosterMaster",
    slug: "postermaster",
    image: "/PosterMasterMockup.png",
    dates: "Feb - March, 2022",
    tagline: "An e-commerce template for movie buffs",
    role: "Developer",
    shortDescription: [
      "Poster Master is a template ecommerce website which sells movie posters. The site features a functioning cart for both signed in users and unregistered guests. Take a look and pick out something that strikes your fancy!",
    ],
    fullDescription: [
      "PosterMaster is the result of an assignment from Fullstack Academy to create an e-commerce template site. It was required that we incorporate a database of buyable goods on the back end, full login/logout functionality, as well as a cart that kept track of what a user had picked out and totaled up the amount due at checkout. What was up to us, however, was how best to put a personal spin on it.",
      "When our team heard that, we knew exactly where we wanted to take the project: to the movies. PosterMaster came out of the desire to build a functioning e-commerce template and marry it to our love of good movies, and the idea for an online shop specifically for movie posters followed pretty naturally from there.",
      "As one of four developers on the project, I was primarily responsible for token authentication when handling user login, as well as designing and implementing the cart functionality, both for signed in users and guests. The result is an e-commerce template site that feels uniquely ours, a one-stop shop for movie lovers looking to fill some extra wall space with the perfect print!",
    ],
    techStack: {
      front: ["React", "Redux"],
      back: ["Node.js", "Express", "Sequelize", "PostgreSQL"],
    },
    links: [
      {
        url: "https://github.com/Litten-Loungers/GraceShopper",
        text: "See my code!",
        newTab: true,
      },
    ],
  },
];

export default projects;
