const packages = [
  {
    image: "/URC.png",
    name: "Ultimate-React-Crossword",
    slug: "ultimate-react-crossword",
    version: "1.0.11",
    role: "Sole Developer",
    shortDescription: [
      "A new React-based Crossword component, with the stated aim of being the most flexible on the market.",
      "Do you want to fire a custom event on every keystroke? Maybe you're looking to change the color of correct clues for an easy mode? What about locking the answers in place when the puzzle is correct? Or maybe you just want to solve a darn crossword puzzle? Either way, Ultimate-React-Crossword is the package for you!",
    ],
    links: [
      {
        url: "/packages/ultimate-react-crossword/docs",
        text: "Read the documentation!",
        newTab: false,
      },
      {
        url: "https://www.npmjs.com/package/ultimate-react-crossword",
        text: "Check it out on NPM!",
        newTab: true,
      },
      {
        url: "/packages/ultimate-react-crossword/example",
        text: "See an example!",
        newTab: false,
      },
    ],
    docs: [
      {
        anchor: "intro",
        header: "Introduction",
        blocks: [
          {
            type: "paragraph",
            text: "This node package came into being because I wanted to do more with crosswords in web apps.",
          },
          {
            type: "paragraph",
            text: [
              "While we were building out ",
              { text: "CrossWars", to: "/projects/crosswars" },
              ", we were floored by how much interactivity the component we ended up using had. But still, there was so much we had to jerry-rig to get it working the way we wanted it to. To be clear, this was not because the component was in any way lacking, but rather because what we envisioned for it was so specific to our project. Our crossword had to be able to reveal squares to the player, to highlight correct answers in the user's chosen color, to selectively disable squares once the answer within was correct, and many other pieces of custom behavior. None of this is at all what you'd usually expect from a pen-and-paper crossword puzzle. But then again, what's the point of putting a crossword puzzle in a web app if we're not going to take full advantage of the medium?",
            ],
          },
          {
            type: "paragraph",
            text: "This is the idea that brought Ultimate-React-Crossword into being: to create a React crossword component that was flexible enough not just to handle everything that CrossWars might ask of it, but anything that any crossword web app or game could ask of it. That's the ambition, anyway.",
          },
          {
            type: "paragraph",
            text: "This project is still in its infancy, and many, many more improvements are still to come! But if you use this component and find it can't do something you need from it, please let me know (so long as you're nice about it)! I'm actively looking for suggestions for how to make this package better.",
          },
        ],
      },
      {
        anchor: "install",
        header: "Installing the Package",
        blocks: [
          {
            type: "paragraph",
            text: "To get started with Ultimate-React-Crossword, simply run the following command in your terminal while inside of your project's directory:",
          },
          {
            type: "code",
            style: "terminal",
            lines: ["npm i ultimate-react-crossword"],
          },
          {
            type: "paragraph",
            text: "Afterwards, you will be able to access the Crossword component, like so:",
          },
          {
            type: "code",
            style: "JS",
            lines: ["import Crossword from 'ultimate-react-crossword';"],
          },
        ],
      },
      {
        anchor: "data",
        header: "Passing Data to the Component",
        blocks: [
          {
            type: "subheader",
            anchor: "required-attributes",
            text: "Required Attributes",
          },
          {
            type: "paragraph",
            text: "The Crossword component expects three attributes. These are:",
          },
          {
            type: "paragraph",
            text: "1. `data`",
          },
          {
            type: "paragraph",
            text: "This is the data for the grid of the puzzle, formatted as a two-dimensional array. Each subarray represents one row, and each object in each subarray represents one cell. Black squares are represented by `null`. Each cell object should have a 'answer' property representing the correct value for that cell. Lastly, each subarray must be of equal length. For example, here's the data for a simple 2x2 grid with a black square in the upper right:",
          },
          {
            type: "code",
            style: "JS",
            lines: [
              "const data = [",
              "  [{ answer: 'A' }, null],",
              "  [{ answer: 'B' }, { answer: 'C' }]",
              "];",
            ],
          },
          {
            type: "paragraph",
            text: "`answer` is the only required property on the objects that make up the data arrays. However, you can also include `disabled` as a property of each square. When the value of that property is `true` the square becomes uneditable to the user. Event handlers (discussed below) can be used to set and unset this property at will.",
          },
          {
            type: "paragraph",
            text: "2. `acrosses`",
          },
          {
            type: "paragraph",
            text: "This is the data for the across clues, formatted as an array of objects. Each object has a `num` property, which expects an integer and represents the number of the clue, and a `clue` property, which expects a string and represents the text of the clue. Below is an example of such an array:",
          },
          {
            type: "code",
            style: "JS",
            lines: [
              "const acrosses = [",
              "  { num: 1, clue: 'Parsley, ___, rosemary and thyme' },",
              "  { num: 5, clue: 'Garden Growth' },",
              "  { num: 7, clue: `Synonym of 'evade' and 'avoid'` },",
              "];",
            ],
          },
          {
            type: "paragraph",
            text: "3. `downs`",
          },
          {
            type: "paragraph",
            text: "This is the data for the down clues, formatted as an array of objects exactly the same as the acrosses. Here's an example:",
          },
          {
            type: "code",
            style: "JS",
            lines: [
              "const downs = [",
              "  { num: 1, clue: 'Shoots out, as lava' },",
              "  { num: 2, clue: 'Nickname for Alexandra' },",
              "  { num: 3, clue: 'Bandage material' },",
              "];",
            ],
          },
          {
            type: "subheader",
            anchor: "optional-attributes",
            text: "Optional Attributes",
          },
          {
            type: "paragraph",
            text: "The Crossword component is also prepared to accept a bevy of optional attributes.",
          },
          {
            type: "paragraph",
            text: "1. `revealAnswers`: A boolean which will reveal the answers of the entire crossword when true.",
          },
          {
            type: "paragraph",
            text: "2. Style attributes: A slew of useful attributes for applying custom styles to the Crossword component. More info can be found in the 'Styling the Crossword' section below.",
          },
          {
            type: "paragraph",
            text: "3. Event Handlers: A collection of event handling attributes which expect functions to be executed on key actions. More info can be found in the Event Handling section below.",
          },
        ],
      },
      {
        anchor: "navigating",
        header: "Navigating the Crossword",
        blocks: [
          {
            type: "paragraph",
            text: "A user can instantly navigate to any square of the crossword by clicking on it, or by clicking on it's corresponding clue from the clue list. The crossword also comes with a few options for moving around the board via keyboard inputs.",
          },
          {
            type: "unorderedlist",
            items: [
              "The arrow keys can be used to move around the board one square at time, skipping over black squares.",
              "The tab key can be used to jump to the next clue, and shift+tab will move to the previous.",
              "Spacebar will switch the direction of focus while still maintaining the user's focus on the current square.",
            ],
          },
        ],
      },
      {
        anchor: "styling",
        header: "Styling the Crossword",
        blocks: [
          {
            type: "subheader",
            text: "Applying Custom Styles",
            anchor: "custom-styles",
          },
          {
            type: "paragraph",
            text: "You can apply custom styling objects directly to each part of the crossword via the following attributes. The attributes each expect an object of style property key value pairs. Below is a series of example style objects and the result on the crossword as they are passed into each custom styling attribute:",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                size: "40%",
                direction: "v",
                justify: "center",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`puzzleStyle`: Applies to the entire space of the crossword puzzle",
                  },
                  {
                    type: "code",
                    style: "JS",
                    lines: [
                      "const styleObject = {",
                      "  backgroundColor: 'lightblue'",
                      "}",
                    ],
                  },
                ],
              },
              {
                type: "image",
                size: "55%",
                src: "/puzzlestyle.png",
              },
            ],
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                size: "40%",
                direction: "v",
                justify: "center",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`crosswordStyle`: Applies to the board of the crossword itself",
                  },
                  {
                    type: "code",
                    style: "JS",
                    lines: [
                      "const styleObject = {",
                      "  border: '3px solid red',",
                      "  padding: '1em',",
                      "  borderRadius: '1em'",
                      "}",
                    ],
                  },
                ],
              },
              {
                type: "image",
                size: "55%",
                src: "/crosswordstyle.png",
              },
            ],
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                size: "40%",
                direction: "v",
                justify: "center",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`squareStyle`: Applies to each square of the puzzle board",
                  },
                  {
                    type: "code",
                    style: "JS",
                    lines: [
                      "const styleObject = {",
                      "  backgroundColor: 'orange',",
                      "  borderRadius: '1000px'",
                      "}",
                    ],
                  },
                ],
              },
              {
                type: "image",
                size: "55%",
                src: "/squarestyle.png",
              },
            ],
          },
          {
            type: "subheader",
            text: "Applying Custom Classes",
            anchor: "custom-classes",
          },
          {
            type: "paragraph",
            text: "If you can't get all the styling you need from the custom style objects, you can also pass strings to be applied as custom classes to each individual component of the crossword.",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                size: "45%",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`puzzleClassnames`: Applies to the entire space of the puzzle",
                  },
                  {
                    type: "paragraph",
                    text: "`crosswordClassnames`: Applies to the crossword's board",
                  },
                  {
                    type: "paragraph",
                    text: "`squareClassnames`: Applies to each square in the crossword's grid",
                  },
                ],
              },
              {
                type: "flex",
                size: "45%",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`clueMenuClassnames`: Applies to the entire clue section of the crossword",
                  },
                  {
                    type: "paragraph",
                    text: "`clueListClassnames`: Applies to each clue list, the acrosses and the downs",
                  },
                  {
                    type: "paragraph",
                    text: "`clueClassnames`: Applies to each clue in the both clue lists",
                  },
                ],
              },
            ],
          },
          {
            type: "subheader",
            text: "Applying Theme Colors",
            anchor: "theme-colors",
          },
          {
            type: "paragraph",
            text: "You can also apply two custom color attributes which determine the color used to indicate the user's focus in the crossword.",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                size: "40%",
                direction: "v",
                justify: "center",
                blocks: [
                  {
                    type: "paragraph",
                    text: "`selectedSquareColor`: Determines the color of the selected square (and the number of the selected clue in the clue list). Defaults to rgb(150, 168, 255)",
                  },
                  {
                    type: "paragraph",
                    text: "`selectedClueColor`: Determines the color of the rest of the squares in the selected clue (as well as the text of the selected clue in the clue list). Defaults to rgb(194, 223, 255)",
                  },
                  {
                    type: "code",
                    style: "JS",
                    lines: [
                      "const selectedSquareColor = 'red';",
                      "",
                      "const selectedClueColor = 'orange';",
                    ],
                  },
                ],
              },
              {
                type: "image",
                size: "55%",
                src: "/puzzlethemecolors.png",
              },
            ],
          },
        ],
      },
      {
        anchor: "events",
        header: "Event Handling",
        blocks: [
          {
            type: "subheader",
            anchor: "functions",
            text: "Event Handler Functions",
          },
          {
            type: "paragraph",
            text: "The Crossword component also comes equipped with a wealth of custom event handling options. Each of the following attributes expects a function which will run upon certain key events as the user solves the puzzle. Each function grants access to the current state of the crossword's data as well. This is the same two-dimensional array you passed into the Crossword component earlier, only updated with a few extra properties: `downNum`, which is the number of the downward clue that this square is associated with, `acrossNum`, which is the number of the across clue this square is associated with, and `displayNum` which is the number that will appear in the upper left of the square, if it has one. These are applied automatically by the component, which is why you did not have to specify them when initally passing in the data.",
          },
          {
            type: "alert",
            text: "Note: If any of the passed functions includes a return statement, the data returned will be treated as the new data of the crossword. As such, these functions should only include return statements if you intend to update the current state of the crossword on the event in question, and all functions that include a return statement should be careful to return only a two-dimensional array of objects, where each subarray is of equal length.",
          },
          {
            type: "paragraph",
            text: "1. `onInput`: Runs whenever the user inputs a letter in any square",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            blocks: [
              {
                type: "flex",
                direction: "v",
                size: "45%",
                blocks: [
                  { type: "paragraph", text: "Expected Parameters:" },
                  {
                    type: "unorderedlist",
                    items: [
                      "`x`: The X position of the square which was typed into",
                      "`y`: The Y position of the square which was typed into",
                      "`input`: The character which was input",
                      "`crosswordData`: The state of the crossword following the input, represented as a two-dimensional array",
                    ],
                  },
                ],
              },
              {
                type: "code",
                lines: [
                  "function onInput(x, y, input, crosswordData) {",
                  "  console.log(x, y, input, crosswordData);",
                  "}",
                ],
                size: "45%",
              },
            ],
          },
          {
            type: "paragraph",
            text: "2. `onCellCorrect`: Runs whenever the user inputs the correct letter in any square",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            size: "45%",
            blocks: [
              {
                type: "flex",
                direction: "v",
                blocks: [
                  { type: "paragraph", text: "Expected Parameters:" },
                  {
                    type: "unorderedlist",
                    items: [
                      "`x`: The X position of the square which was typed into",
                      "`y`: The Y position of the square which was typed into",
                      "`input`: The character which was input",
                      "`crosswordData`: The state of the crossword following the input, represented as a two-dimensional array",
                    ],
                  },
                ],
              },
              {
                type: "code",
                lines: [
                  "function onCellCorrect(x, y, input, crosswordData) {",
                  "  console.log(x, y, input, crosswordData);",
                  "}",
                ],
                size: "45%",
              },
            ],
          },
          {
            type: "paragraph",
            text: "3. `onClueCorrect`: Runs whenever the user finishes inputting a complete, correct answer to any clue",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            size: "45%",
            blocks: [
              {
                type: "flex",
                direction: "v",
                blocks: [
                  { type: "paragraph", text: "Expected Parameters:" },
                  {
                    type: "unorderedlist",
                    items: [
                      "`num`: The number of the clue which was correctly answered",
                      "`direction`: The direction of the clue which was correctly answered (representes as a string, either 'across' or 'down'",
                      "`crosswordData`: The state of the crossword following the input, represented as a two-dimensional array",
                    ],
                  },
                ],
              },
              {
                type: "code",
                lines: [
                  "function onClueCorrect(num, direction, crosswordData) {",
                  "  console.log(num, direction, crosswordData);",
                  "}",
                ],
                size: "45%",
              },
            ],
          },
          {
            type: "paragraph",
            text: "4. `onPuzzleFinished`: Runs whenever the user has input a guess into each square of the crossword, but the entire puzzle is not yet correct",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            size: "45%",
            blocks: [
              {
                type: "flex",
                direction: "v",
                blocks: [
                  { type: "paragraph", text: "Expected Parameters:" },
                  {
                    type: "unorderedlist",
                    items: [
                      "`crosswordData`: The state of the crossword following the input, represented as a two-dimensional array",
                    ],
                  },
                ],
              },
              {
                type: "code",
                lines: [
                  "function onPuzzleFinished(crosswordData) {",
                  "  console.log(crosswordData);",
                  "}",
                ],
                size: "45%",
              },
            ],
          },
          {
            type: "paragraph",
            text: "5. `onPuzzleCorrect`: Runs whenever the user has input the correct letter into every square of the puzzle",
          },
          {
            type: "flex",
            direction: "h",
            justify: "space-between",
            size: "45%",
            blocks: [
              {
                type: "flex",
                direction: "v",
                blocks: [
                  { type: "paragraph", text: "Expected Parameters:" },
                  {
                    type: "unorderedlist",
                    items: [
                      "`crosswordData`: The state of the crossword following the input, represented as a two-dimensional array",
                    ],
                  },
                ],
              },
              {
                type: "code",
                lines: [
                  "function onPuzzleCorrect(crosswordData) {",
                  "  console.log(crosswordData);",
                  "}",
                ],
                size: "45%",
              },
            ],
          },
          {
            type: "subheader",
            text: "Navigating Event Overlap",
            anchor: "overlap",
          },
          {
            type: "paragraph",
            text: "In the event that multiple event handlers are triggered at once, they will all run in sequence. The only exception to this is `onPuzzleFinished`/`onPuzzleCorrect`, which operate mutually exclusively. `onPuzzleFinished` will only run if the entire puzzle has input, but not all of it is yet correct, and likewise, when the final correct input is entered, it will trigger `onPuzzleCorrect`, and not `onPuzzleFinished`. One other small note about `onPuzzleFinished`: It will not run when the user makes a new input to an already finished grid. Instead, the input square must previously have been empty to trigger `onPuzzleFinished`.",
          },
          {
            type: "paragraph",
            text: "Picture a user entering the last cell to correctly finish a puzzle. In this case, the events will fire in this order:",
          },
          {
            type: "unorderedlist",
            items: [
              "The `onInput` function",
              "The `onCellCorrect` function",
              "The `onClueCorrect` function (for the across clue)",
              "The `onClueCorrect` function (for the down clue)",
              " The `onPuzzleCorrect` function",
            ],
          },
          {
            type: "paragraph",
            text: "Lastly, if each of these functions has a return statement which modifies the data of the puzzle, they will execute in sequence, each taking the last's result as it's starting, input data. This allows each to manipulate the data of the crossword in turn.",
          },
          {
            type: "subheader",
            text: "Common Uses for Event Handling",
            anchor: "uses",
          },
          {
            type: "paragraph",
            text: "Below are just a few illustrations of how these events can be useful to a project, both by directly manipulating the state of the crossword, or by simply triggering external mechanisms in the program.",
          },
          {
            type: "paragraph",
            text: "Disabling the squares of a clue when the answer is correct:",
          },
          {
            type: "code",
            lines: [
              "function onClueCorrect(num, direction, crosswordData) {",
              "  return crosswordData.map((row) =>",
              "    row.map((square) => {",
              "      if (!square) {",
              "        return square;",
              "      }",
              "      if (direction === 'across') {",
              "        return square.acrossNum === num",
              "          ? { ...square, disabled: true }",
              "          : square;",
              "      } else {",
              "        return square.downNum === num ? { ...square, disabled: true } : square;",
              "      }",
              "    }),",
              "  );",
              "}",
              "",
              "// This is an example of an event function which directly manipulates crossword data",
            ],
          },
          {
            type: "paragraph",
            text: "Alerting the player when the puzzle is finished, but not yet completely correct:",
          },
          {
            type: "code",
            lines: [
              "function onPuzzleFinished(crosswordData) {",
              "  alert('Not quite...');",
              "}",
              "",
              "// This is an example of an event function which triggers an event WITHOUT manipulating crossword data",
            ],
          },
          {
            type: "paragraph",
            text: "Alerting the player when the entire puzzle is correct, and disabling every square of the puzzle:",
          },
          {
            type: "code",
            lines: [
              "function onPuzzleCorrect(crosswordData) {",
              "  alert('Congrats!');",
              "  return crosswordData.map((row) =>",
              "    row.map((square) => {",
              "      if (square) {",
              "        return { ...square, disabled: true };",
              "      } else {",
              "        return square;",
              "      }",
              "    }),",
              "  );",
              "}",
              "",
              "// This is an example of an event function which triggers an event AND directly manipulates crossword data",
            ],
          },
        ],
      },
      {
        anchor: "up-next",
        header: "More is Coming Soon!",
        blocks: [
          {
            type: "paragraph",
            text: "There's still plenty more to add to this module! Here's a brief list of features I hope to add in the near future:",
          },
          {
            type: "unorderedlist",
            items: [
              "Rebus support",
              "Custom key bindings",
              "Standardized styling/class name parameters",
              "Classname property for individual squares in the data array for individual square styling",
              "Decoupling event detection from keystrokes, to allow for events to fire when data is input from other sources than the user's keyboard",
              "A Redux plug in to track puzzle state in a global store",
              "Optionally saving puzzle state in localStorage to allow for a refresh of the page without wiping the grid",
              "Highlighting clues other than the one in focus, that are referenced in the text of the current clue",
              "Circled squares",
              "Mobile responsivity",
              "Decoupling the clue lists from the board so they can be added to the page separately",
              "Helper functions to simplify manipulating crossword data on events",
              "And more!",
            ],
          },
          {
            type: "paragraph",
            text: "Still think this list is incomplete? Feel free to tell me so! Let me know what else should be a feature!",
          },
          {
            type: "paragraph",
            text: "But for now, enjoy the puzzle and watch this space for updates!",
          },
        ],
      },
    ],
  },
];

export default packages;
