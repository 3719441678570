import styles from "./DocNav.module.scss";

function DocNav({ data, setFocus }) {
  return (
    <div className={"card " + styles.docNav}>
      {data.map((section) =>
        section.header && section.anchor ? (
          <>
            <h3
              onClick={() => {
                setFocus(section.anchor);
              }}
            >
              {section.header}
            </h3>
            {section.blocks.map((subsection) =>
              subsection.type === "subheader" &&
              subsection.text &&
              subsection.anchor ? (
                <h4
                  onClick={() => {
                    setFocus(subsection.anchor);
                  }}
                >
                  {subsection.text}
                </h4>
              ) : (
                <></>
              ),
            )}
          </>
        ) : (
          <></>
        ),
      )}
    </div>
  );
}

export default DocNav;
