import styles from "./FullPageStyle.module.scss";
import { Documentation, DocNav } from "../components/NPMpages";
import { useParams } from "react-router-dom";
import { packages } from "../content";
import { useState } from "react";

function PackageDocPage() {
  const { packageName } = useParams();

  const [focus, setFocus] = useState(null);
  const selectedPackage = packages.find((pkg) => pkg.slug === packageName);
  return (
    <div className={styles.siteBackground}>
      <div className={styles.documentationPage}>
        <DocNav data={selectedPackage.docs} setFocus={setFocus} />
        <Documentation data={selectedPackage.docs} focus={focus} />
      </div>
    </div>
  );
}

export default PackageDocPage;
