import { Link } from "react-router-dom";
import styles from "./DocParagraph.module.scss";

function DocParagraph({ text, flex, size }) {
  const styleObject = flex && size ? { flex: `0 0 ${size}` } : {};
  if (typeof text === "string") {
    text = [text];
  }
  let result = [];
  for (let substring of text) {
    if (typeof text === "object") {
      result.push(<Link to={substring.to}>{substring.text}</Link>);
    }
    let ps = [];
    let ph = "";
    for (let i = 0; i < substring.length; i++) {
      const letter = substring[i];
      if (letter === "`") {
        ps.push(ph);
        ph = "";
      } else if (i === substring.length - 1) {
        ph += letter;
        ps.push(ph);
      } else {
        ph += letter;
      }
    }
    result.push(ps.map((p, i) => (i % 2 !== 0 ? <span>{p}</span> : <>{p}</>)));
  }

  return (
    <p style={styleObject} className={styles.docParagraph}>
      {result.map((str) => (
        <>{str}</>
      ))}
    </p>
  );
}

export default DocParagraph;
