// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBody_projectBody__R4\\+3P {
  display: flex;
  margin-bottom: 2em;
  width: calc(90% - 1em);
  margin-inline: auto;
  gap: 1em;
}
.ProjectBody_projectBody__R4\\+3P > div {
  width: 50%;
}

@media (max-width: 900px) {
  .ProjectBody_projectBody__R4\\+3P {
    margin-top: 2em;
    flex-direction: column;
    width: calc(100% - 2em);
  }
  .ProjectBody_projectBody__R4\\+3P > div {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/projectPage/ProjectBody.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,UAAA;AACJ;;AAGA;EACE;IACE,eAAA;IACA,sBAAA;IACA,uBAAA;EAAF;EAEE;IACE,WAAA;EAAJ;AACF","sourcesContent":[".projectBody {\n  display: flex;\n  margin-bottom: 2em;\n  width: calc(90% - 1em);\n  margin-inline: auto;\n  gap: 1em;\n\n  & > div {\n    width: 50%;\n  }\n}\n\n@media (max-width: 900px) {\n  .projectBody {\n    margin-top: 2em;\n    flex-direction: column;\n    width: calc(100% - 2em);\n\n    & > div {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectBody": `ProjectBody_projectBody__R4+3P`
};
export default ___CSS_LOADER_EXPORT___;
