import DocHeader from "./DocHeader";
import styles from "./DocSection.module.scss";
import DocBlock from "./DocBlock.js";
import { useRef, useEffect } from "react";

function DocSection({ section, focus }) {
  const ref = useRef();

  useEffect(() => {
    const { current } = ref;
    if (current && focus && focus === section.anchor) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  }, [focus]);

  return (
    <div className={styles.docSection} ref={ref}>
      <DocHeader text={section.header} />
      {section.blocks.map((block) => (
        <DocBlock block={block} focus={focus} />
      ))}
    </div>
  );
}

export default DocSection;
