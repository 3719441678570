import styles from "./DocSubHeader.module.scss";
import { useEffect, useRef } from "react";

function DocSubHeader({ text, flex, size, focus }) {
  const styleObject = flex && size ? { flex: `0 0 ${size}` } : {};

  const ref = useRef();

  useEffect(() => {
    const { current } = ref;
    if (current && focus) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  }, [focus]);

  return (
    <h2 style={styleObject} ref={ref} className={styles.docSubHeader}>
      {text}
    </h2>
  );
}

export default DocSubHeader;
