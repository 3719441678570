// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlexSection_flex__S2U9\\+ {
  display: flex;
}

.FlexSection_verticalFlex__a1Mz2 {
  flex-direction: column;
}

.FlexSection_horizontalFlex__Eotiz {
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/FlexSection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".flex {\n  display: flex;\n}\n\n.verticalFlex {\n  flex-direction: column;\n}\n\n.horizontalFlex {\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": `FlexSection_flex__S2U9+`,
	"verticalFlex": `FlexSection_verticalFlex__a1Mz2`,
	"horizontalFlex": `FlexSection_horizontalFlex__Eotiz`
};
export default ___CSS_LOADER_EXPORT___;
