import { Link } from "react-router-dom";

import styles from "./NodePackage.module.scss";

function NodePackage({ pkg }) {
  return (
    <div className={styles.package}>
      <h2>{pkg.name}</h2>
      <h3>{pkg.role}</h3>
      <div className={styles.packageTop}>
        <img src={pkg.image} />
        <div>
          {pkg.shortDescription.map((para) => (
            <p>{para}</p>
          ))}
          <div className={styles.packageLinks}>
            {pkg.links.map((link) => (
              <Link
                className="link"
                target={link.newTab ? "_blank" : ""}
                to={link.url}
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NodePackage;
