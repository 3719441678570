// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CrosswordExample_crosswordExample__UqSKq {
  width: 75%;
  margin: 1em auto;
  min-width: 900px;
  max-width: 1000px;
}

.CrosswordExample_hidden__yfls4 {
  display: none;
}

.CrosswordExample_crosswordClues__4Xr\\+q h1 {
  font-size: 2em;
}

@media (max-width: 800px) {
  .CrosswordExample_puzzle__8c9Z0 {
    flex-direction: column;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .CrosswordExample_clueMenu__d1AJa {
    width: 100% !important;
  }
  .CrosswordExample_crosswordClues__4Xr\\+q > div {
    max-height: 60vh;
  }
  .CrosswordExample_crossword__PKtMP {
    width: 100%;
  }
  .CrosswordExample_crosswordExample__UqSKq {
    width: 90%;
    margin: 1em auto;
    min-width: unset;
    max-width: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/CrosswordExample.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAGE;EACE,cAAA;AAAJ;;AAIA;EACE;IACE,sBAAA;IACA,mCAAA;IAAA,8BAAA;EADF;EAIA;IACE,sBAAA;EAFF;EAME;IACE,gBAAA;EAJJ;EAQA;IACE,WAAA;EANF;EASA;IACE,UAAA;IACA,gBAAA;IACA,gBAAA;IACA,gBAAA;EAPF;AACF","sourcesContent":[".crosswordExample {\n  width: 75%;\n  margin: 1em auto;\n  min-width: 900px;\n  max-width: 1000px;\n}\n\n.hidden {\n  display: none;\n}\n\n.crosswordClues {\n  & h1 {\n    font-size: 2em;\n  }\n}\n\n@media (max-width: 800px) {\n  .puzzle {\n    flex-direction: column;\n    height: fit-content !important;\n  }\n\n  .clueMenu {\n    width: 100% !important;\n  }\n\n  .crosswordClues {\n    & > div {\n      max-height: 60vh;\n    }\n  }\n\n  .crossword {\n    width: 100%;\n  }\n\n  .crosswordExample {\n    width: 90%;\n    margin: 1em auto;\n    min-width: unset;\n    max-width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crosswordExample": `CrosswordExample_crosswordExample__UqSKq`,
	"hidden": `CrosswordExample_hidden__yfls4`,
	"crosswordClues": `CrosswordExample_crosswordClues__4Xr+q`,
	"puzzle": `CrosswordExample_puzzle__8c9Z0`,
	"clueMenu": `CrosswordExample_clueMenu__d1AJa`,
	"crossword": `CrosswordExample_crossword__PKtMP`
};
export default ___CSS_LOADER_EXPORT___;
