import packages from "./nodepackages";
import projects from "./projects";

const homeSections = [
  {
    anchor: "about",
    title: "About Me",
    textContent: [
      "Across my career, I've been a reporter, a researcher, a translator, a teacher, and a software engineer, but the one thing that unites these varied interests is my underlying passion for learning and growing.",
      "I consider myself first and foremost a student of life and am always looking to broaden my horizons, be it with a new language, a new instrument, or even just a new food! It was that curiosity and openness to new things that led me to begin programming and developing software professionally. With many personal projects and years of industry experience under my belt, I still greet each day and each challenge as an opportunity to learn and become an even stronger developer.",
      "My specializations include JavaScript, Python, React, Redux, SQL, Git, and more, but my strongest assets are my natural curiosity and my love of puzzle solving, both of which allow me to be not just a knowledgeable engineer, but a flexible one as well.",
      "I currently work as a Senior Solutions Engineer at Taboola, where I implement ad placements for clients and develop internal tools.",
    ],
  },
  {
    anchor: "npm",
    title: "My Node Packages",
    packageContent: packages,
  },
  {
    anchor: "projects",
    title: "My Projects",
    projectContent: projects,
  },
  {
    anchor: "contact",
    title: "Contact Me",
    links: [
      { to: "mailto:lmlucasmc8@gmail.com", text: "Email me!", newTab: false },
      {
        to: "https://www.linkedin.com/in/lucas-mcgill/",
        text: "Connect with me on LinkedIn!",
        newTab: true,
      },
      {
        to: "https://github.com/LucasMc17",
        text: "Follow me on GitHub!",
        newTab: true,
      },
    ],
  },
];

export default homeSections;
