// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NodePackage_package__YQlEy {
  padding: 2em 0;
}

.NodePackage_packageTop__X9eX0 {
  display: flex;
  align-items: center;
}
.NodePackage_packageTop__X9eX0 img {
  width: 40%;
  padding: 1em;
  object-fit: contain;
}
.NodePackage_packageTop__X9eX0 > div {
  width: 60%;
}

.NodePackage_packageLinks__lo7aC {
  display: flex;
  justify-content: space-around;
  gap: 0.5em;
}

@media (max-width: 1200px) {
  .NodePackage_packageTop__X9eX0 {
    flex-direction: column;
  }
  .NodePackage_packageTop__X9eX0 img {
    width: 100%;
    object-fit: contain;
  }
  .NodePackage_packageTop__X9eX0 > div {
    width: 100%;
  }
  .NodePackage_packageLinks__lo7aC {
    flex-direction: column;
    gap: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/NodePackage.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,UAAA;EACA,YAAA;EACA,mBAAA;AAEJ;AAGE;EACE,UAAA;AADJ;;AAKA;EACE,aAAA;EACA,6BAAA;EACA,UAAA;AAFF;;AAKA;EACE;IACE,sBAAA;EAFF;EAIE;IACE,WAAA;IACA,mBAAA;EAFJ;EAKE;IACE,WAAA;EAHJ;EAOA;IACE,sBAAA;IACA,QAAA;EALF;AACF","sourcesContent":[".package {\n  padding: 2em 0;\n}\n\n.packageTop {\n  display: flex;\n  align-items: center;\n  & img {\n    width: 40%;\n    padding: 1em;\n    object-fit: contain;\n    // margin: -4%;\n    // object-position: 90%;\n  }\n\n  & > div {\n    width: 60%;\n  }\n}\n\n.packageLinks {\n  display: flex;\n  justify-content: space-around;\n  gap: 0.5em;\n}\n\n@media (max-width: 1200px) {\n  .packageTop {\n    flex-direction: column;\n\n    & img {\n      width: 100%;\n      object-fit: contain;\n    }\n\n    & > div {\n      width: 100%;\n    }\n  }\n\n  .packageLinks {\n    flex-direction: column;\n    gap: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"package": `NodePackage_package__YQlEy`,
	"packageTop": `NodePackage_packageTop__X9eX0`,
	"packageLinks": `NodePackage_packageLinks__lo7aC`
};
export default ___CSS_LOADER_EXPORT___;
