import DocParagraph from "./DocParagraph";
import DocSubHeader from "./DocSubHeader";
import { CodeBlock } from "../../utilities";
import DocUnorderedList from "./DocUnorderedList";
import DocImage from "./DocImage";
import FlexSection from "./FlexSection";
import DocAlert from "./DocAlert";

function DocBlock({ block, flex, focus }) {
  return (
    <>
      {block.type === "paragraph" && (
        <DocParagraph text={block.text} flex={flex} size={block.size} />
      )}
      {block.type === "subheader" && (
        <DocSubHeader
          focus={focus === block.anchor}
          text={block.text}
          flex={flex}
          size={block.size}
        />
      )}
      {block.type === "code" && (
        <CodeBlock
          style={block.style}
          lines={block.lines}
          flex={flex}
          size={block.size}
        />
      )}
      {block.type === "unorderedlist" && (
        <DocUnorderedList items={block.items} flex={flex} size={block.size} />
      )}
      {block.type === "image" && (
        <DocImage size={block.size} src={block.src} flex={flex} />
      )}
      {block.type === "flex" && (
        <FlexSection
          direction={block.direction}
          blocks={block.blocks}
          size={block.size}
          flex={flex}
          justify={block.justify}
          align={block.align}
        />
      )}
      {block.type === "alert" && (
        <DocAlert text={block.text} flex={flex} size={block.size} />
      )}
    </>
  );
}

export default DocBlock;
