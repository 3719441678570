import styles from "./ProjectLinks.module.scss";
import { Link } from "react-router-dom";

function ProjectLinks({ info }) {
  return (
    <div className={"card " + styles.projectLinks}>
      <h2>Even More to See</h2>
      <div>
        {info.map((link) => (
          <Link
            className="link"
            to={link.url}
            target={link.newTab ? "_blank" : ""}
          >
            {link.text}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ProjectLinks;
