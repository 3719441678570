import HomeSection from "./HomeSection";
import styles from "./HomeFeed.module.scss";

function HomeFeed({ sections, focus }) {
  return (
    <main className={styles.homeFeed}>
      {sections.map((section) => (
        <HomeSection info={section} focus={focus === section.anchor} />
      ))}
    </main>
  );
}

export default HomeFeed;
