// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectLinks_projectLinks__sXSqX {
  height: calc(25% - 2.5em);
  margin-bottom: 1em;
}
.ProjectLinks_projectLinks__sXSqX > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 32px);
  width: 80%;
  margin-inline: auto;
}
.ProjectLinks_projectLinks__sXSqX h2 {
  text-align: center;
}

@media (max-width: 900px) {
  .ProjectLinks_projectLinks__sXSqX {
    height: -moz-fit-content;
    height: fit-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/projectPage/ProjectLinks.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,yBAAA;EACA,UAAA;EACA,mBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;;AAIA;EACE;IACE,wBAAA;IAAA,mBAAA;EADF;AACF","sourcesContent":[".projectLinks {\n  height: calc(25% - 2.5em);\n  margin-bottom: 1em;\n\n  & > div {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    height: calc(100% - 32px);\n    width: 80%;\n    margin-inline: auto;\n  }\n\n  & h2 {\n    text-align: center;\n  }\n}\n\n@media (max-width: 900px) {\n  .projectLinks {\n    height: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectLinks": `ProjectLinks_projectLinks__sXSqX`
};
export default ___CSS_LOADER_EXPORT___;
