import styles from "./FullPageStyle.module.scss";
import { useParams } from "react-router-dom";
import { projects } from "../content";
import { useState } from "react";
import {
  ProjectDescription,
  ProjectHero,
  ProjectBody,
} from "../components/projectPage";

function Project({ data }) {
  const { projectName } = useParams();
  const [projectInfo, setProjectInfo] = useState(
    projects.find((proj) => proj.slug === projectName),
  );
  console.log(projectInfo);
  return (
    <div className={styles.siteBackground}>
      <div className={styles.projectPage}>
        <ProjectHero info={projectInfo} />
        <ProjectBody info={projectInfo} />
      </div>
    </div>
  );
}

export default Project;
