// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocAlert_docAlert__7Kv0x {
  background-color: pink;
  border: 2px solid red;
  border-radius: 2em;
  padding: 1em;
  margin-inline: 10%;
}
.DocAlert_docAlert__7Kv0x > alert {
  display: flex;
  justify-content: space-between;
}
.DocAlert_docAlert__7Kv0x > alert > svg {
  flex: 0 0 5%;
  max-width: 50px;
}
.DocAlert_docAlert__7Kv0x > alert > p {
  flex: 0.9 0 85%;
}`, "",{"version":3,"sources":["webpack://./src/components/NPMpages/documentation/DocAlert.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;AACJ;AACI;EACE,YAAA;EACA,eAAA;AACN;AAEI;EACE,eAAA;AAAN","sourcesContent":[".docAlert {\n  background-color: pink;\n  border: 2px solid red;\n  border-radius: 2em;\n  padding: 1em;\n  margin-inline: 10%;\n\n  & > alert {\n    display: flex;\n    justify-content: space-between;\n\n    & > svg {\n      flex: 0 0 5%;\n      max-width: 50px;\n    }\n\n    & > p {\n      flex: 0.9 0 85%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docAlert": `DocAlert_docAlert__7Kv0x`
};
export default ___CSS_LOADER_EXPORT___;
