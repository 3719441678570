import DocListItem from "./DocListItem";
import styles from "./DocUnorderedList.module.scss";

function DocUnorderedList({ items, flex, size }) {
  const styleObject = flex && size ? { flex: `0 0 ${size}` } : {};
  return (
    <ul style={styleObject} className={styles.docUnorderedList}>
      {items.map((item) => (
        <DocListItem item={item} />
      ))}
    </ul>
  );
}

export default DocUnorderedList;
