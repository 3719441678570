import styles from "./ProjectBody.module.scss";
import ProjectDescription from "./ProjectDescription";
import ProjectLinks from "./ProjectLinks";
import TechStack from "./TechStack";

function ProjectBody({ info }) {
  return (
    <div className={styles.projectBody}>
      <div>
        <ProjectDescription info={info} />
      </div>
      <div>
        <ProjectLinks info={info.links} />
        <TechStack info={info.techStack} />
      </div>
    </div>
  );
}

export default ProjectBody;
