import styles from "./Documentation.module.scss";
import DocSection from "./DocSection";

function Documentation({ data, focus }) {
  return (
    <div className={styles.docs}>
      <div className="card">
        {data.map((section) => (
          <DocSection section={section} focus={focus} />
        ))}
      </div>
    </div>
  );
}

export default Documentation;
