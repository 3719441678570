import { Link } from "react-router-dom";
import styles from "./HomeSection.module.scss";
import { useRef, useEffect } from "react";
import NodePackage from "./NodePackage";

function HomeSection({ info, focus }) {
  const ref = useRef();

  useEffect(() => {
    const { current } = ref;
    if (current && focus) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  }, [focus]);

  const { title, textContent, projectContent, packageContent, links } = info;
  return (
    <div className={styles.homeSection} ref={ref}>
      <h1>{title}</h1>
      <div className="card">
        {textContent &&
          (typeof textContent === "string" ? (
            <p>{textContent}</p>
          ) : (
            textContent.map((para) => <p>{para}</p>)
          ))}
        {projectContent &&
          projectContent.map((project) => (
            <div className={styles.project}>
              <h2>{project.name}</h2>
              <h3>{project.role}</h3>
              <div className={styles.projectTop}>
                <Link to={`/projects/${project.slug}`}>
                  <img src={project.image} />
                </Link>
                <div>
                  {project.shortDescription.map((para) => (
                    <p>{para}</p>
                  ))}
                  <div className={styles.projectLinks}>
                    {[
                      {
                        url: `/projects/${project.slug}`,
                        text: "Learn more!",
                        newTab: false,
                      },
                      ...project.links,
                    ].map((link) => (
                      <Link
                        className="link"
                        target={link.newTab ? "_blank" : ""}
                        to={link.url}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {packageContent &&
          packageContent.map((pkg) => <NodePackage pkg={pkg} />)}
        {links && (
          <div className={styles.contactSection}>
            {links.map((link) => (
              <Link
                className="link"
                to={link.to}
                target={link.newTab ? "_blank" : ""}
              >
                {link.text}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeSection;
