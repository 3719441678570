import styles from "./ProjectDescription.module.scss";

function ProjectDescription({ info }) {
  return (
    <div className={"card "}>
      <h1>{info.tagline}</h1>
      {info.fullDescription.map((para) => (
        <p>{para}</p>
      ))}
    </div>
  );
}

export default ProjectDescription;
